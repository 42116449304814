<template>
  <ul class="list-unstyled">
    <li
      v-for="(item,index) in navList"
      :key="index"
      class="bg-white px-0"
    >
      <router-link
        class="p-3 text-decoration-none link-dark"
        :class="$style[`nav-link`]"
        :to="item.to"
      >
        <Icon
          :class="$style.icon"
          :name="item.icon"
        />
        {{ item.title }}
      </router-link>
    </li>
  </ul>
</template>
<script>
import { ref } from 'vue'
export default {
  name: 'MemberNav',
  setup () {
    const navList = ref([
      {
        to: '/member/detail',
        icon: 'profile',
        title: '會員資料'
      },
      {
        to: '/member/password',
        icon: 'password',
        title: '修改密碼'
      },
      {
        to: '/member/orders',
        icon: 'order',
        title: '訂單查詢'
      }
      // {
      //   to: '/member/orders',
      //   title: '進行中訂單'
      // },
      // {
      //   to: '/member/orders/complete',
      //   title: '已完成訂單'
      // },
      // {
      //   to: '/member/bonus/added',
      //   title: '購物金入賬列表'
      // },
      // {
      //   to: '/member/bonus/used',
      //   title: '購物金使用列表'
      // }
    ])

    return { navList }
  }
}
</script>
<style lang="scss" module>
.icon {
  fill: $dark;
  stroke: $dark;
  height: 1.4rem;
  margin: 0.3rem;
  width: 1.4rem;
  transition: fill 0.3s, stroke 0.3s;
}

.nav-link {
  display: block;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:global(.router-link-active) {
  background-color: $primary;
  color: $white;
  font-weight: bold;

  .icon {
    fill: $white;
    stroke: $white;
  }
}
</style>
