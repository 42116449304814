<template>
  <div class="bg-grey-light">
    <div
      class="container-lg py-5"
    >
      <div class="row">
        <div class="col-md-4 col-lg-3 col-xxl-2">
          <MemberNav
            class="position-sticky"
            :class="$style[`top120px`]"
          />
        </div>
        <div class="col-md-8 col-lg-9 col-xxl-10">
          <router-view v-slot="{Component}">
            <template v-if="Component">
              <suspense>
                <component
                  :is="Component"
                  class="flex-grow-1"
                />
              </suspense>
            </template>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import MemberNav from '@/components/MemberNav.vue'
import { useStore } from 'vuex'
import { computed, inject } from 'vue'
import { useRouter } from 'vue-router'
export default {
  name: 'Member',
  components: {
    MemberNav
  },
  setup (props) {
    const { getters } = useStore()
    const { push } = useRouter()

    const memberID = computed(() => getters['member/getMemberID'])

    const setAlert = inject('setAlert')
    if (!memberID.value || memberID.value === '') {
      setAlert(true, false, '請先登入會員！')
      push('/login')
    }
  }
}
</script>
<style lang="scss" module>

.top120px {
  top:120px;
}

</style>
